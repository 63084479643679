<template>
  <div class="feedback">
    <div class="textarea">
      <van-field
        v-model="message"
        type="textarea"
        maxlength="500"
        placeholder="请输入您的意见与反馈"
        show-word-limit
      />
    </div>
    <div class="mobile">
      <van-field v-model="info" placeholder="手机号/QQ/邮箱（选填）" />
    </div>
    <div class="upload">
      <div class="title">上传图片</div>
      <van-uploader v-model="fileList" :after-read="afterRead" multiple />
    </div>
    <van-goods-action safe-area-inset-bottom>
      <van-goods-action-button
        type="danger"
        text="提交"
        color="#21A905"
        :disabled="fileList.length == 0 || !message"
        @click="isSubmit"
      />
    </van-goods-action>
  </div>
</template>

<script>
import { httpAction, uploadAction } from "@/api/manage";
import action from "@/utils/constant";
import { Field, Uploader, GoodsAction, GoodsActionButton, Toast } from "vant";

export default {
  name: "feedback",
  components: {
    [Field.name]: Field,
    [Uploader.name]: Uploader,
    [GoodsAction.name]: GoodsAction,
    [GoodsActionButton.name]: GoodsActionButton,
    [Toast.name]: Toast,
  },
  data() {
    return {
      message: "",
      info: "",
      fileList: [],
      imgs: [],
    };
  },
  methods: {
    // 上传图片
    afterRead(file) {
      file.status = "uploading";
      file.message = "上传中...";
      uploadAction("/common/upload_file", action.POST, file.file).then(
        (res) => {
          if (res.code === 200) {
            file.status = "";
            this.imgs.push(res.result.url);
          } else {
            file.status = "failed";
            file.message = "上传失败";
          }
        }
      );
    },
    // 提交反馈
    isSubmit() {
      httpAction(
        "/system/app/feedback/add",
        action.POST,
        {
          contactInfo: this.message,
          img: this.imgs,
          phone: this.info,
          source: 4,
        },
        () => {
          Toast.success("提交成功");
          this.imgs = [];
          this.fileList = [];
          this.info = "";
          this.message = "";
        }
      );
    },
  },
};
</script>
<style lang="less" scoped>
.feedback {
  padding: 8px 10px;
  .textarea {
    &/deep/ .van-field {
      background: #f0f1f2;
      height: 180px;
      border-radius: 6px;
      .van-field__control {
        height: 136px;
        font-size: 15px;
        &::placeholder {
          color: #afb3b0;
          font-size: 15px;
        }
      }
    }
  }
  .mobile {
    margin-top: 16px;
    .van-field {
      background: #f0f1f2;
      border-radius: 6px;
      padding: 14px 12px;
    }
    &/deep/input {
      height: 22px;
    }
  }
  .upload {
    margin-top: 32px;
    .title {
      color: #2f3133;
      font-size: 16px;
      height: 16px;
      line-height: 16px;
      margin-bottom: 16px;
    }
    &/deep/.van-uploader__preview-delete {
      color: #21a905;
    }
    &/deep/.van-uploader__upload-icon {
      font-size: 38px;
      color: #afb3b0;
    }
    &/deep/.van-uploader__upload {
      background: #eeeff0;
      border-radius: 6px;
    }
  }
  &/deep/.van-goods-action-button {
    margin: 0 10px;
    height: 44px;
    border-radius: 22px;
    font-size: 16px;
  }
  &/deep/.van-goods-action {
    padding: 8px 0;
  }
}
</style>
